/* src/components/Loader.css */
.loader {
    display: flex;
    flex-direction: column; /* Para alinear el mensaje debajo del spinner */
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  }
  
  .spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 8px solid var(--color_003);
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  .loader-message {
    margin-top: 15px; /* Espacio entre el spinner y el mensaje */
    font-size: 16px;
    color: #bbbaba; /* Color del mensaje */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  