.comment-section {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}
h3 {
  text-align: center;
}

.comments-list {
  margin-bottom: 30px;
  max-height: 300px;
  overflow-y: auto;
}

.comment-item {
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 0;
  font-family: 'Roboto', sans-serif;
  color: #333;
  transition: background-color 0.3s;
}

.comment-item:hover {
  background-color: #f0f0f0;
}

.add-comment textarea {
  width: 95%; /* Reduce el ancho al 95% para que no se salga del margen */
  padding: 10px;
  margin-bottom: 12px;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  resize: vertical;
  transition: border-color 0.3s;
}

.add-comment textarea:focus {
  border-color: #ffffff;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}


.add-comment button {
  padding: 12px 24px;
  background-color: #FFCC00;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.add-comment button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.add-comment button:active {
  background-color: #001380;
  transform: translateY(0);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .comment-section {
    padding: 16px;
  }

  .comment-item {
    padding: 10px 0;
  }

  .add-comment button {
    width: 100%;
    padding: 12px;
  }
}
