/* - - - </> [FONT] </> - - - */

/* Poppins (Regular) Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

/* Poppins (Medium) Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

/* Poppins (SemiBold) Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

/* Poppins (Bold) Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

/* Poppins (ExtraBold) Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

/* Poppins (Black) Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');

/* - - - </> [ROOT] </> - - - */
:root
{
    --color_001: #FFFFFF;
    --color_002: #1B1B1B;
    --color_003: #FFCC00;
    --color_004: #00217E;
    --color_005: #EF112E;
}

/* - - - </> [HTML] </> - - - */
html, body
{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    color: var(--color_001);
    background: var(--color_002);
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}

/* - - - </> [ROOT] </> - - - */
#root
{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}


/* - - - </> [DIV] </> - - - */
/* Estilos generales para el botón de carga de archivos */
.form-file-label {
 width: 100%;
    height: 40px;
    display: flex;
    cursor: pointer;
    border: 2px solid #ffffff;
    position: relative;
    align-items: center;
    border-radius: 50px;
    margin-bottom: 25px;
    box-sizing: border-box;
    justify-content: center;
}

  
  /* Estilo para cuando el botón es hover */
  .form-file-label:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  
  /* Estilo para el texto dentro del botón */
  .form-file-button {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  /* Estilo para mostrar un icono dentro del botón */
  .form-file-label::before {
    content: '📹'; /* Icono de cámara o video */
    font-size: 20px;
    margin-right: 8px;
    vertical-align: middle;
  }
  
  /* Estilo para el nombre del archivo */
  .file-name {
    display: block;
    margin-top: 8px;
    font-size: 14px;
    color: #333;
  }
.form
{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
}

/* - - - </> [DIV] .form-header </> - - - */
.form-header
{
    width: 100%;
    max-width: 325px;
    display: flex;
    margin-top: 25px;
    align-items: center;
    margin-bottom: 25px;
    justify-content: space-between;
}

/* - - - </> [ICON] </> - - - */
.form-header-icon
{
  cursor: pointer;
  font-size: 30px;
  margin-left: 9%;
}

/* - - - </> [DIV] </> - - - */
.circle-wrapper
{
  display: flex;
  align-items: center;
}

/* - - - </> [SPAN] </> - - - */
.form-circle
{
  width: 15px;
  height: 15px;
  margin-left: 10px;
  border-radius: 50%;
  display: inline-block;
}


/* - - - </> [DIV] .form-image </> - - - */
.form-image
{
    width: 100%;
    max-width: 300px;
    user-select: none;
    margin-bottom: 25px;
    pointer-events: none;
}

/* - - - </> [DIV] .title-wrapper- </> - - - */
.title-wrapper-
{
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    flex-direction: column;
    justify-content: center;
}

/* - - - </> [TEXT] .form-title </> - - - */
.form-title
{
    margin: 0;
    font-size: 32px; /* Ajustado para pantallas más pequeñas */
    user-select: none;
    line-height: 100%;
    pointer-events: none;
    text-align: center;
}

/* - - - </> [TEXT] .form-desc </> - - - */
.form-desc
{
    width: 100%;
    max-width: 325px;
    font-size: 14px;
    margin-bottom: 0;
    user-select: none;
    pointer-events: none;
    text-align: center;
}

/* - - - </> [DIV] .form-wrapper </> - - - */
.form-wrapper
{
    width: 100%;
    max-width: 325px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
}

/* - - - </> [DIV] .input-wrapper </> - - - */
.input-wrapper
{
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 25px;
}

/* - - - </> [ICON] .form-input-icon </> - - - */
.form-input-icon
{
    left: 20px;
    font-size: 20px;
    position: absolute;
    box-sizing: border-box;
    color: var(--color_002);
    z-index: 2; /* Asegura que el ícono esté sobre el input */
}

/* - - - </> [INPUT] .form-input </> - - - */
.form-input
{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    font-size: 14px;
    padding-left: 50px; /* Espacio suficiente para el ícono */
    border-radius: 50px;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    position: relative;
    z-index: 1;
}

/* - - - </> [SELECT] </> - - - */
.form-input
{
    appearance:none;
    -moz-appearance:none;
    -webkit-appearance:none;
}

/* - - - </> [ICON] .form-dropdown-icon </> - - - */
.form-dropdown-icon
{
    right: 15px;
    font-size: 30px;
    user-select: none;
    position: absolute;
    pointer-events: none;
    color: var(--color_002);
    z-index: 2;
}

/* - - - </> [BUTTON] .form-upload, .form-button </> - - - */
.form-upload, .form-button
{
    width: 100%;
    height: 40px;
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    border-radius: 50px;
    margin-bottom: 25px;
    box-sizing: border-box;
    justify-content: center; /* Asegura que el texto esté centrado */
}

.form-button {
    color: var(--color_002);
    background: var(--color_003);
}

.form-upload {
    color: var(--color_001);
    background: var(--color_002);
    border: 2px solid var(--color_001);
}

/* - - - </> [ICON] .form-upload-icon, .form-button-icon </> - - - */
.form-upload-icon, .form-button-icon
{
    left: 20px;
    font-size: 20px;
    user-select: none;
    position: absolute;
    pointer-events: none;
    z-index: 2;
}

/* - - - </> [TEXT] .form-button-text, .form-upload-text </> - - - */
.form-button-text, .form-upload-text
{
    left: 0;
    right: 0;
    font-size: 14px;
    font-weight: 500;
    user-select: none;
    position: absolute;
    pointer-events: none;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    z-index: 1;
}

/* - - - </> [TEXT] .form-message </> - - - */
.form-message
{
    margin: 0;
    width: 100%;
    max-width: 325px;
    font-size: 10px;
    user-select: none;
    margin-bottom: 25px;
    pointer-events: none;
}
/* Responsive adjustments */
@media screen and (max-width: 480px) {
  .form-title {
      font-size: 24px;
  }
  
  .form-desc {
      font-size: 12px;
  }
  
  .form-input {
      padding-left: 40px; /* Espacio para el ícono en móviles */
      font-size: 12px;
  }
  
  .form-button-text, .form-upload-text {
      font-size: 12px;
  }
  
  .form-header {
      margin-top: 15px;
      margin-bottom: 15px;
  }
  
  .form-image {
      max-width: 250px;
  }
  
  .input-wrapper {
      margin-bottom: 15px;
  }
  
  .form-message {
      font-size: 9px;
  }
}