/* - - - </> [FONT] </> - - - */

/* Poppins (Regular) Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

/* Poppins (Medium) Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

/* Poppins (SemiBold) Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

/* Poppins (Bold) Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

/* Poppins (ExtraBold) Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

/* Poppins (Black) Font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');

/* - - - </> [ROOT] </> - - - */
:root
{
    --color_001: #FFFFFF;
    --color_002: #1B1B1B;
    --color_003: #FFCC00;
    --color_004: #00217E;
    --color_005: #EF112E;
}

/* - - - </> [HTML] </> - - - */
html
{
    margin: 0;
    padding: 0;
    width: 100%;
}

/* - - - </> [ROOT] </> - - - */
#root
{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

/* - - - </> [BODY] </> - - - */
.Home-page
{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    color: var(--color_001);
    background: var(--color_002);
    font-family: 'Poppins', sans-serif;
}

/* - - - </> [DIV] </> - - - */
.home
{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* - - - </> - - - </> - - - </> [SPAN] </> - - - </> - - - </> - - - */
/* - - - </> - - - </> - - - </> [SPAN] </> - - - </> - - - </> - - - */
/* - - - </> - - - </> - - - </> [SPAN] </> - - - </> - - - </> - - - */

/* - - - </> [DIV] </> - - - */
.circle-wrapper
{
  width: 325px;
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
  align-items: center;
  justify-content: end;
}

/* - - - </> [SPAN] </> - - - */
.home-circle
{
  width: 15px;
  height: 15px;
  margin-left: 10px;
  border-radius: 50%;
  display: inline-block;
}

/* - - - </> - - - </> - - - </> [TEXT] </> - - - </> - - - </> - - - */
/* - - - </> - - - </> - - - </> [TEXT] </> - - - </> - - - </> - - - */
/* - - - </> - - - </> - - - </> [TEXT] </> - - - </> - - - </> - - - */

/* - - - </> [DIV] </> - - - */
.title-wrapper
{
  width: 325px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

/* - - - </> [TEXT] </> - - - */
.home-title
{
  margin: 0;
  font-size: 40px;
  user-select: none;
  line-height: 100%;
  pointer-events: none;
}

/* - - - </> [TEXT] </> - - - */
.home-title span
{
  font-weight: 700;
}

/* - - - </> [TEXT] </> - - - */
.home-desc
{
  width: 325px;
  font-size: 14px;
  user-select: none;
  pointer-events: none;
}

/* - - - </> - - - </> - - - </> [BUTTON] </> - - - </> - - - </> - - - */
/* - - - </> - - - </> - - - </> [BUTTON] </> - - - </> - - - </> - - - */
/* - - - </> - - - </> - - - </> [BUTTON] </> - - - </> - - - </> - - - */

/* - - - </> [DIV] </> - - - */
.button-wrapper
{
  width: 325px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: space-between;
}

/* - - - </> [BUTTON] </> - - - */
.home-button
{
  width: 150px;
  height: 40px;
  border: none;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  border-radius: 50px;
  box-sizing: border-box;
  color: var(--color_002);
}

/* - - - </> [ICON] </> - - - */
.home-button-icon
{
  left: 20px;
  font-size: 20px;
  user-select: none;
  position: absolute;
  pointer-events: none;
}

/* - - - </> [TEXT] </> - - - */
.home-button-text
{
  right: 20px;
  font-size: 14px;
  font-weight: 500;
  user-select: none;
  position: absolute;
  pointer-events: none;
  font-family: 'Poppins', sans-serif;
}

/* - - - </> - - - </> - - - </> [SEARCH] </> - - - </> - - - </> - - - */
/* - - - </> - - - </> - - - </> [SEARCH] </> - - - </> - - - </> - - - */
/* - - - </> - - - </> - - - </> [SEARCH] </> - - - </> - - - </> - - - */

/* - - - </> [DIV] </> - - - */
.home-search
{
  width: 325px;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 25px;
}

/* - - - </> [INPUT] </> - - - */
.home-search-input
{
  width: 100%;
  height: 40px;
  border: none;
  outline: none;
  font-size: 14px;
  padding: 0px 20px;
  border-radius: 50px;
  color: var(--color_002);
  background: var(--color_001);
  font-family: 'Poppins', sans-serif;
}

/* - - - </> [ICON] </> - - - */
.home-search-icon
{
  right: 20px;
  cursor: pointer;
  font-size: 20px;
  position: absolute;
  color: var(--color_002);
  background: var(--color_001);
}

/* - - - </> - - - </> - - - </> [SUBTITLE] </> - - - </> - - - </> - - - */
/* - - - </> - - - </> - - - </> [SUBTITLE] </> - - - </> - - - </> - - - */
/* - - - </> - - - </> - - - </> [SUBTITLE] </> - - - </> - - - </> - - - */

/* - - - </> [DIV] </> - - - */
.subtitle-wrapper
{
  width: 325px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  justify-content: space-between;
}

/* - - - </> [TEXT] </> - - - */
.home-subtitle
{
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  user-select: none;
  line-height: 100%;
  pointer-events: none;
}

/* - - - </> [LINE] </> - - - */
.home-line
{
  margin: 0;
  width: 250px;
  user-select: none;
  pointer-events: none;
  color: var(--color_001);
}

/* - - - </> - - - </> - - - </> [VIDEO] </> - - - </> - - - </> - - - */
/* - - - </> - - - </> - - - </> [VIDEO] </> - - - </> - - - </> - - - */
/* - - - </> - - - </> - - - </> [VIDEO] </> - - - </> - - - </> - - - */

/* - - - </> [DIV] </> - - - */
.video-wrapper
{
  width: 325px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  flex-direction: column;
  justify-content: center;
}

/* - - - </> [DIV] </> - - - */
.home-video
{
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
}

/* - - - </> [DIV] </> - - - */
.image-wrapper
{
  width: 100%;
  height: 200px;
  position: relative;
  margin-bottom: 25px;
}

/* - - - </> [SPAN] </> - - - */
.home-video-time
{
  z-index: 3;
  right: 20px;
  width: 75px;
  height: 40px;
  bottom: 20px;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  align-items: center;
  border-radius: 50px;
  color: var(--color_002);
  justify-content: center;
  background: var(--color_001);
}

/* - - - </> [SPAN] </> - - - */
.home-video-layer
{
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 24px;
  background: rgb(27,27,27);
  background: linear-gradient(180deg, rgba(27,27,27,0) 0%, rgba(27,27,27,1) 100%); 
}

/* - - - </> [IMAGE] </> - - - */
.home-video-image
{
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
  position: absolute;
  border-radius: 24px;
  pointer-events: none;
}

/* - - - </> [DIV] </> - - - */
.desc-wrapper
{
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
}

/* - - - </> [TEXT] </> - - - */
.home-video-title
{
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  user-select: none;
  line-height: 100%;
  margin-bottom: 10px;
  pointer-events: none;
}

/* - - - </> [TEXT] </> - - - */
.home-video-hour
{
  margin: 0;
  font-size: 14px;
  user-select: none;
  line-height: 100%;
  margin-bottom: 25px;
  pointer-events: none;
}
