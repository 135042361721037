/* Importación de fuentes */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

#root {
    width: 100%;
    height: 100%;
}

.page-welcome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color_003);
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
}

.circle-wrapper {
    display: flex;
    gap: 15px;
    justify-content: center;
    margin-bottom: 20px;
}

.loading-circle {
    width: 5vw;
    height: 5vw;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    display: inline-block;
}

.loading-image {
    max-width: 80%;
    height: auto;
    user-select: none;
    pointer-events: none;
    margin-bottom: 20px;
}

.continue-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #131313;
    color: #ffffff;
    border: none;
    padding: 12px 24px;
    font-size: 2vw;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    text-decoration: none;
    width: 100%;
    max-width: 300px;
    margin-top: 20px;
}

.continue-button .icon {
    margin-right: 8px;
    font-size: 5vw;
}

@media (max-width: 768px) {
    .continue-button {
        font-size: 4vw;
    }

    .continue-button .icon {
        font-size: 6vw;
    }
}

@media (max-width: 480px) {
    .loading-image {
        max-width: 100%;
    }
}
