/* Importación de fuentes Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');

/* - - - </> [ROOT] </> - - - */
html, body
{
    margin: 0;
    padding: 0;
    width: 100%;
    color: var(--color_001);
    background: var(--color_002);
    font-family: 'Poppins', sans-serif;
}

/* - - - </> [BODY] .About-page </> - - - */
.About-page {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    color: var(--color_001);
    background: var(--color_002);
    font-family: 'Poppins', sans-serif;
}

/* - - - </> [DIV] .about </> - - - */
.about
{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
}

/* - - - </> [HEADER] .about-header </> - - - */
.about-header
{
    width: 100%;
    max-width: 325px;
    display: flex;
    margin-top: 25px;
    align-items: center;
    margin-bottom: 25px;
    justify-content: space-between;
}

/* - - - </> [ICON] .about-header-icon </> - - - */
.about-header-icon
{
    cursor: pointer;
    font-size: 30px;
}

/* - - - </> [DIV] .circle-wrapper </> - - - */
.circle-wrapper
{
    display: flex;
    align-items: center;
}

/* - - - </> [SPAN] .about-circle </> - - - */
.about-circle
{
    width: 15px;
    height: 15px;
    margin-left: 10px;
    border-radius: 50%;
    display: inline-block;
}

/* - - - </> [IMG] .about-image </> - - - */
.about-image
{
    width: 100%;
    max-width: 300px;
    user-select: none;
    margin-bottom: 25px;
    pointer-events: none;
}

/* - - - </> [DIV] .title-wrapper </> - - - */
.title-wrapper
{
    width: 100%;
    max-width: 325px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    flex-direction: column;
    justify-content: center;
    margin-left: 3%;
}

.title-wrapper > p:last-child
{
    margin: 0;
    text-align: center;
}

/* - - - </> [TEXT] .about-title </> - - - */
.about-title
{
    margin: 0;
    font-size: 40px;
    user-select: none;
    line-height: 100%;
    pointer-events: none;
}

.about-title span
{
    font-weight: 700;
}

/* - - - </> [TEXT] .about-desc </> - - - */
.about-desc
{
    width: 100%;
    max-width: 325px;
    font-size: 14px;
    user-select: none;
    pointer-events: none;
}

/* - - - </> [BUTTON] .about-button </> - - - */
.about-button
{
    width: 100%;
    max-width: 325px;
    height: 40px;
    border: none;
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    border-radius: 50px;
    box-sizing: border-box;
    color: var(--color_002);
    background: var(--color_003);
}

/* - - - </> [ICON] .about-button-icon </> - - - */
.about-button-icon
{
    left: 20px;
    font-size: 20px;
    user-select: none;
    position: absolute;
    pointer-events: none;
}

/* - - - </> [TEXT] .about-button-text </> - - - */
.about-button-text
{
    left: 0;
    right: 0;
    font-size: 14px;
    font-weight: 500;
    user-select: none;
    position: absolute;
    pointer-events: none;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

/* Responsive adjustments */
@media screen and (max-width: 480px) {
    .about-title {
        font-size: 32px;
    }
    
    .about-desc {
        font-size: 12px;
    }
    
    .about-button-text {
        font-size: 12px;
    }
    
    .about-header {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    
    .about-image {
        max-width: 250px;
    }
    
    .title-wrapper {
        margin-bottom: 15px;
    }
    
    .about-header-icon {
        font-size: 24px;
    }
}
/* Ajustes generales para pantallas pequeñas */
@media (max-width: 375px) {
  .about-header,
  .title-wrapper,
  .about-desc,
  .about-button {
    width: 90%; /* Ajuste de ancho para pantallas pequeñas */
    margin-left: 5%;
    margin-right: 5%;
  }

  .about-title {
    font-size: 30px; /* Reducir el tamaño del título */
    text-align: center;
  }

  .about-desc {
    font-size: 12px; /* Reducir el tamaño del texto descriptivo */
    text-align: center;
  }

  .about-button {
    height: 35px; /* Ajustar la altura del botón */
    font-size: 12px; /* Reducir el tamaño del texto dentro del botón */
  }

  .about-button-icon {
    font-size: 16px; /* Ajustar el tamaño del icono */
    left: 15px;
  }

  .about-image {
    width: 250px; /* Reducir el tamaño de la imagen */
  }
}
