/* src/components/Loader.css */
.loadVideo {
    position: fixed; /* Asegúrate de que se posicione respecto a la ventana del navegador */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column; /* Para alinear el mensaje debajo del spinner */
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8); /* Fondo semi-transparente para cubrir el contenido */
    z-index: 1000; /* Asegúrate de que el loader esté por encima de otros elementos */
    text-align: center;
  }
  
  .spinnerLoad {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 8px solid var(--color_003);
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  .loadd-message {
    margin-top: 15px; /* Espacio entre el spinner y el mensaje */
    font-size: 16px;
    color: #bbbaba; /* Color del mensaje */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  