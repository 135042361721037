/* src/components/VideoList.css */
.video-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  padding: 0 20px;
  margin: 0 auto;
}

.video-item {
  width: 100%;
  max-width: 700px;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  background: #ffffff; /* Fondo blanco para un diseño más limpio */
}

.video-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  margin: 10px;
  background: #f5f5f5; /* Fondo gris claro para contraste sutil */
  color: #333; /* Color de texto gris oscuro */
  padding: 8px 12px;
  border-radius: 8px;
  word-wrap: break-word;
  font-size: 14px;
  position: relative;
}

.video-name .icon-container {
  display: flex;
  gap: 10px;
}

.video-name .icon {
  font-size: 20px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;
}

.video-name .icon:hover {
  color: #007bff; /* Color azul claro para hover */
}

video {
  width: 100%;
  height: auto;
  border-radius: 0; /* Sin bordes redondeados para un look más limpio */
}

@media (max-width: 768px) {
  .video-list {
    padding: 0 15px;
  }

  .video-item {
    max-width: 100%;
    margin: 10px 0;
  }
}
